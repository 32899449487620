// src/routes/authRoutes.tsx
import React from "react";
import { Navigate } from "react-router-dom";

const LoginPage = React.lazy(() => import("../pages/auth/LoginPage"));
const SignupPage = React.lazy(() => import("../pages/auth/SignupPage"));
// const VerifyPage = React.lazy(() => import("../pages/auth/VerifyPage"));

interface RouteConfig {
  path: string;
  element: React.LazyExoticComponent<React.FC<any>> | React.FC<any>;
}

const authRoutes: RouteConfig[] = [
  {
    path: "/login",
    element: LoginPage,
  },
  {
    path: "/signup",
    element: SignupPage,
  },
  /*
  {
    path: "/verify",
    element: VerifyPage,
  },
  */
  {
    path: "*",
    element: () => <Navigate to="/login" replace />,
  },
];

export default authRoutes;
