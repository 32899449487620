// src/routes/index
import React from "react";
import { RouteConfig } from "./types";
import authRoutes from "./authRoutes";
import dashboardRoutes from "./dashboardRoutes";
import commonRoutes from "./commonRoutes";
import LandingPage from "../pages/LandingPage";

const routes: RouteConfig[] = [
  {
    path: "/",
    element: LandingPage,
  },
  ...authRoutes,
  ...dashboardRoutes,
  ...commonRoutes,
];

export default routes;
