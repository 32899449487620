// src/App.tsx
import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./routes";
import AuthenticatedLayout from "./layouts/AuthenticatedLayout";
import PublicLayout from "./layouts/PublicLayout";
import ErrorBoundary from "./components/common/ErrorBoundary";

function App() {
  const renderRoutes = (routesArray: any[]) =>
    routesArray.map((route, index) => {
      // Determine the layout based on the route path or other criteria
      let Layout = PublicLayout;

      // Example: If the route is part of dashboardRoutes, use AuthenticatedLayout
      if (
        route.path.startsWith("/dashboard") ||
        route.path.startsWith("/inventory") ||
        route.path.startsWith("/pricing")
      ) {
        Layout = AuthenticatedLayout;
      }

      // If you have more sophisticated logic, consider adding a `layout` property in RouteConfig
      return (
        <Route
          key={index}
          path={route.path}
          element={route.element ? React.createElement(Layout) : null}
        >
          <Route
            index
            element={
              typeof route.element === "function"
                ? React.createElement(route.element)
                : React.createElement(route.element)
            }
          />
        </Route>
      );
    });

  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>{renderRoutes(routes)}</Routes>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
