// src/components/Navbar.jsx
import React, { useState, useEffect } from "react";
import logo from "@assets/imgs/logo_anyqrc_black_png.png";
import { NavLink, useLocation } from "react-router-dom";
import {
  SunIcon,
  MoonIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Optional: Persist dark mode preference
    const darkModePreference = localStorage.getItem("theme") === "dark";
    setIsDarkMode(darkModePreference);
    if (darkModePreference) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);
  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    if (newMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  };

  const hiddenPaths = ["/login", "/signup", "/verify"];
  const hideLinks = hiddenPaths.includes(location.pathname);

  const isDashboard = location.pathname.startsWith("/dashboard");

  const isLoggedIn = !!localStorage.getItem("access_token");

  return (
    <header className="sticky top-0 w-full bg-light-base dark:bg-dark-base shadow-md z-50 transition-colors duration-300">
      <div className="max-w-7xl mx-auto flex items-center justify-between p-4">
        {/* Logo */}
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            window.location.href = "/";
          }}
          aria-label="Homepage"
        >
          <img src={logo} alt="Logo" className="w-24 h-auto" />
        </div>

        {/* Hamburger Menu & Links */}
        <div className="flex items-center">
          {/* Desktop Links */}
          {!isMobile && !hideLinks && (
            <nav className="flex items-center space-x-6">
              {isDashboard ? (
                <>
                  <NavLink
                    to="/dashboard"
                    className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                    onClick={closeMenu}
                  >
                    Profile
                  </NavLink>
                  <NavLink
                    to="/dashboard/settings"
                    className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                    onClick={closeMenu}
                  >
                    Settings
                  </NavLink>
                  {isLoggedIn ? (
                    <button
                      className="text-light-danger dark:text-dark-danger hover:text-light-dangerHover dark:hover:text-dark-dangerHover transition-colors"
                      onClick={() => {
                        localStorage.removeItem("access_token");
                        closeMenu();
                      }}
                    >
                      Logout
                    </button>
                  ) : (
                    <NavLink
                      to="/login"
                      className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                      onClick={closeMenu}
                    >
                      Login
                    </NavLink>
                  )}
                </>
              ) : (
                <>
                  <NavLink
                    to="/dashboard"
                    className="px-4 py-2 bg-light-special dark:bg-dark-special text-white rounded-full hover:bg-light-specialHover dark:hover:bg-dark-specialHover transition-colors flex items-center"
                    onClick={closeMenu}
                  >
                    Try now
                    <span className="ml-2 transform rotate-45">→</span>
                  </NavLink>
                  <NavLink
                    to="/pricing"
                    className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                    onClick={closeMenu}
                  >
                    Pricing
                  </NavLink>
                  <NavLink
                    to="/about"
                    className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                    onClick={closeMenu}
                  >
                    About
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                    onClick={closeMenu}
                  >
                    Contact
                  </NavLink>
                  {isLoggedIn ? (
                    <button
                      className="text-light-danger dark:text-dark-danger hover:text-light-dangerHover dark:hover:text-dark-dangerHover transition-colors"
                      onClick={() => {
                        localStorage.removeItem("access_token");
                        closeMenu();
                      }}
                    >
                      Logout
                    </button>
                  ) : (
                    <NavLink
                      to="/login"
                      className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                      onClick={closeMenu}
                    >
                      Login
                    </NavLink>
                  )}
                </>
              )}
            </nav>
          )}

          {/* Mobile Hamburger */}
          {isMobile && !hideLinks && (
            <button
              onClick={toggleMenu}
              className="md:hidden ml-2 p-2 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              aria-label="Toggle Menu"
              aria-expanded={isMenuOpen}
            >
              {isMenuOpen ? (
                <XMarkIcon
                  className="h-6 w-6 text-light-main dark:text-dark-main"
                  aria-hidden="true"
                />
              ) : (
                <Bars3Icon
                  className="h-6 w-6 text-light-main dark:text-dark-main"
                  aria-hidden="true"
                />
              )}
            </button>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobile && isMenuOpen && !hideLinks && (
        <nav
          className="md:hidden bg-light-muted dark:bg-dark-muted fixed top-0 right-0 w-3/4 h-full shadow-lg z-40 transform transition-transform duration-300"
          aria-label="Mobile Navigation"
        >
          <div className="flex flex-col p-6 space-y-4">
            {isDashboard ? (
              <>
                <NavLink
                  to="/dashboard"
                  className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                  onClick={closeMenu}
                >
                  Profile
                </NavLink>
                <NavLink
                  to="/dashboard/settings"
                  className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                  onClick={closeMenu}
                >
                  Settings
                </NavLink>
                {isLoggedIn ? (
                  <button
                    className="text-light-danger dark:text-dark-danger hover:text-light-dangerHover dark:hover:text-dark-dangerHover transition-colors"
                    onClick={() => {
                      localStorage.removeItem("access_token");
                      closeMenu();
                    }}
                  >
                    Logout
                  </button>
                ) : (
                  <NavLink
                    to="/login"
                    className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                    onClick={closeMenu}
                  >
                    Login
                  </NavLink>
                )}
              </>
            ) : (
              <>
                <NavLink
                  to="/dashboard"
                  className="px-4 py-2 bg-light-special dark:bg-dark-special text-white rounded-full hover:bg-light-specialHover dark:hover:bg-dark-specialHover transition-colors flex items-center justify-center"
                  onClick={closeMenu}
                >
                  Try now
                  <span className="ml-2 transform rotate-45">→</span>
                </NavLink>
                <NavLink
                  to="/pricing"
                  className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                  onClick={closeMenu}
                >
                  Pricing
                </NavLink>
                <NavLink
                  to="/about"
                  className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                  onClick={closeMenu}
                >
                  About
                </NavLink>
                <NavLink
                  to="/contact"
                  className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                  onClick={closeMenu}
                >
                  Contact
                </NavLink>
                {isLoggedIn ? (
                  <button
                    className="text-light-danger dark:text-dark-danger hover:text-light-dangerHover dark:hover:text-dark-dangerHover transition-colors"
                    onClick={() => {
                      localStorage.removeItem("access_token");
                      closeMenu();
                    }}
                  >
                    Logout
                  </button>
                ) : (
                  <NavLink
                    to="/login"
                    className="text-light-main dark:text-dark-main hover:text-light-accent dark:hover:text-dark-accent transition-colors"
                    onClick={closeMenu}
                  >
                    Login
                  </NavLink>
                )}
              </>
            )}
            {/* Dark Mode Toggle for Mobile */}
            <button
              onClick={toggleDarkMode}
              className="mt-4 flex items-center justify-center p-2 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              aria-label="Toggle Dark Mode"
            >
              {isDarkMode ? (
                <>
                  <SunIcon
                    className="h-6 w-6 text-yellow-500"
                    aria-hidden="true"
                  />
                  <span className="ml-2">Light Mode</span>
                </>
              ) : (
                <>
                  <MoonIcon
                    className="h-6 w-6 text-gray-800"
                    aria-hidden="true"
                  />
                  <span className="ml-2">Dark Mode</span>
                </>
              )}
            </button>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Navbar;
