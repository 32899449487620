// src/routes/dashboardRoutes.tsx
import React from "react";
import { Navigate } from "react-router-dom";

const DashboardPage = React.lazy(() => import("../pages/dashboard/Dashboard"));
const InventoryList = React.lazy(
  () => import("../pages/inventories/InventoryList")
);

const AddInventory = React.lazy(
  () => import("../pages/inventories/AddInventory")
);
const ComingSoon = React.lazy(() => import("../pages/comingsoon/ComingSoon"));
const PricingPage = React.lazy(() => import("../pages/pricing/PricingPage"));

interface RouteConfig {
  path: string;
  element: React.LazyExoticComponent<React.FC<any>> | React.FC<any>;
}

const dashboardRoutes: RouteConfig[] = [
  {
    path: "/dashboard",
    element: DashboardPage,
  },
  {
    path: "/inventory",
    element: InventoryList,
  },
  {
    path: "/inventory/:parentId",
    element: InventoryList,
  },
  {
    path: "/inventory/add",
    element: AddInventory,
  },
  {
    path: "/inventory/:parentId/add",
    element: AddInventory,
  },
  {
    path: "/pricing",
    element: PricingPage,
  },
  {
    path: "/parking",
    element: ComingSoon,
  },
  {
    path: "/events",
    element: ComingSoon,
  },
  {
    path: "/lost-found",
    element: ComingSoon,
  },
  {
    path: "/business-card",
    element: ComingSoon,
  },
  {
    path: "*",
    element: () => <Navigate to="/dashboard" replace />,
  },
];

export default dashboardRoutes;
