// src/layouts/PublicLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "@components/common/Navbar";

const PublicLayout: React.FC = () => {
  return (
    <>
      <main>
        <Navbar />
        <Outlet />
      </main>
    </>
  );
};

export default PublicLayout;
