// src/layouts/AuthenticatedLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "@components/common/Navbar";

const AuthenticatedLayout: React.FC = () => {
  return (
    <>
      <Navbar />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default AuthenticatedLayout;
