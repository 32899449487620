// src/routes/authRoutes.tsx
import React from "react";
import { Navigate } from "react-router-dom";

const QrPage = React.lazy(() => import("../pages/common/QrPage"));

interface RouteConfig {
  path: string;
  element: React.LazyExoticComponent<React.FC<any>> | React.FC<any>;
}

const commonRoutes: RouteConfig[] = [
  {
    path: "/qr/:qr_id",
    element: QrPage,
  },
];

export default commonRoutes;
